@import url('fonts.css');

:root {
    --white: #ffffff;

    --ica-smoke-white: #ededed;
    --ica-purple: #bf348b;
    --ica-dark-gray: #414141;
    --ica-light-gray: #f3f4f5;
    --ica-very-light-gray: #cdcdcd;
    --ica-pale-gray: #e5e7eb;
    --cloud-gray: #ababab;
    --ica-gray: #6f6f6f;
    --ica-light-blue: #0096d6;
    --ica-dark-blue: #09375c;
    --ica-red: #ef1010;
    --ica-overlay-grey: rgba(0, 0, 0, 0.65);

    --font-body: var(--font-noto);
    --font-header: 'Gotham', 'Open Sans', 'serif';
    --font-secondary: var(--font-sans);
    --font-regular: 400;
    --font-bold: 700;

    --font-size-base: 16px;
    --font-size-reg: 18px;
    --font-size-l: 22px;
    --font-size-md: 14px;
    --font-size-sm: 12px;
    --line-height-base: 125%;

    --primary: var(--ica-dark-gray);
    --overlay: var(--ica-overlay-grey);
    --gray: var(--ica-gray);
    --dark-gray: var(--ica-dark-gray);

    /* Buttons */
    --btn-radius: 3px;
    --btn-lg-radius: 5px;
    --btn-xl-radius: 10px;
    --btn-font-size: var(--font-size-base);
    --btn-lg-font-size: var(--font-size-reg);
    --btn-disabled: var(--ica-pale-gray);
    --btn-primary-bg: var(--ica-dark-gray);
    --btn-primary-text: var(--white);
    --btn-primary-weight: var(--font-bold);
    --btn-primary-hover: var(--ica-gray);

    --btn-secondary-bg: var(--white);
    --btn-secondary-border: var(--ica-dark-gray);
    --btn-secondary-weight: var(--font-bold);
    --btn-secondary-text: var(--ica-dark-gray);
    --btn-secondary-disabled: var(--ica-pale-gray);

    /* Accents */
    --accent-1: var(--ica-light-blue);
    --accent-2: var(--ica-purple);

    /* Header */
    --header-bg: var(--white);
    --header-height-md: 140px;
    --header-height: 90px;
    --icons-height: 24px;
    --icons-height-md: 40px;
    --icons-label-weight: var(--font-bold);
    --icons-label-size-md: 15px;
    --icons-label-size: 10px;
    --account-hover: var(--ica-light-blue);
    --search-hover: var(--ica-purple);
    --cart-indicator: var(--ica-purple);
    --cart-hover: var(--ica-dark-blue);
    --menu-hover: var(--ica-dark-blue);
    --menu-bg: var(--ica-dark-gray);

    /*Cart */
    --close-icon: var(--ica-dark-gray);
    --promo-price: var(--ica-purple);
    --discounted-price-size: var(--font-size-sm);
    --discounted-price-font: var(--font-normal);
    --discounted-price-weight: var(--font-body);

    /* menu */
    --menu-font: var(--font-secondary);
    --menu-cat-color: var(--ica-purple);
    --menu-cat-size: 21px;
    --menu-cat-size-md: calc(21px + (27 - 21) * ((100vw - 744px) / (1920 - 744)));
    --menu-subcat-color: var(--ica-dark-gray);
    --menu-subcat-size: 18px;
    --menu-subcat-size-md: calc(18px + (23 - 18) * ((100vw - 744px) / (1920 - 744)));
    --menu-weight: var(--font-bold);
    --menu-shadow: -80px 0px 150px 0px rgba(0, 0, 0, 0.65);

    /* Footer */
    --footer-bg: var(--ica-dark-gray);
    --footer-link-color: var(--white);

    /* Input */
    --input-bg: var(--white);
    --input-border: var(--ica-dark-gray);
    --input-error: var(--ica-red);
    --input-text: var(--ica-dark-gray);
    --label-font: var(--font-body);
    --label-weight: var(--font-bold);
    --label-text: var(--ica-dark-gray);
    --input-disabled: var(--ica-very-light-gray);
    --input-placeholder: var(--cloud-gray);

    /* Checkout step */
    --checkout-step-bg: var(--white);
    --checkout-step-border-color: var(--ica-very-light-gray);
    --checkout-delegates: var(--ica-purple);

    /* Checkout footer */
    --checkout-page-bg: var(--ica-smoke-white);
    --checkout-footer-bg: var(--white);
    --checkout-footer-bg-mobile: var(--ica-pale-gray);

    /* Checkout */
    --checkout-border: var(--ica-pale-gray);

    /* Tile */
    --tile-primary: var(--white);
    --tile-accent: var(--ica-light-blue);
    --tile-radius: 20px;
    --tile-font-family: var(--font-body);
    --tile-product-name-font-family: var(--font-header);
    --tile-font-weight: var(--font-regular);
    --tile-product-name-font-weight: var(--font-bold);

    /* Slider */
    --arrows-border: var(--ica-dark-blue);
    --slider-title: var(--ica-dark-gray);
    --slider-weight: var(--font-bold);

    /* Search */
    --search-bg: var(--ica-light-gray);
    --search-accent: var(--ica-purple);
    --search-text: var(--ica-dark-gray);
    --search-text-hover: var(--white);
    --search-icon: var(--ica-purple);

    /* Account */
    --hub-link-bg: var(--ica-purple);
    --hub-link-color: var(--white);
    --chip-individual: var(--ica-purple);
    --chip-business: var(--ica-light-blue);

    --register-note: var(--ica-purple);

    /* Learner Page */
    --learner-accent: var(--ica-purple);
    --learner-accent-1: var(--ica-light-blue);
}

.order-info-width {
    max-width: calc(100% - 136px);
}
